import React, { lazy } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//context providers
import { AuthProvider } from "./context/auth-context";
//components
import PrivateRoute from "./components/restricted-routes/private-route";
import AdvisorRoute from "./components/restricted-routes/advisor-route";
import AuthorizedRoute from "./components/restricted-routes/authorized-route";
import { useEffect } from "react";

import Values from "./routes/financial-planning/values";
import { Suspense } from "react";
import LoadingPage from "./components/loadingPage";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/errorHandling/error-fallback-page";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";

//Google Analyctics
import ReactGA from "react-ga4";
//--------------------------------------
//constants
import { AUTHORIZATION } from "./constants";
//react helmet
import { Helmet } from "react-helmet";

//components
import AdminRoute from "./components/restricted-routes/admin-route";
import HouseholdsSmartTable from "./routes/AdvisorPortal/clients/HouseholdsSmartTable";
import UsersSmartTable from "./routes/AdvisorPortal/clients/UsersSmartTable";

import HouseholdHome from "./routes/AdvisorPortal/household";
import ManagerRoute from "./components/restricted-routes/manager-route";
import CustomDragLayer from "./components/drag-drop/CustomDragLayer";
import YouvestProvider from "./context/app-context.js";
import Advisor from "./routes/AdvisorPortal/household/Settings/components/Advisor";
import AppProvider from "./context/app-context.js";
const StripeTransition = lazy(() => import("./routes/Client-Portal/stripe-transition"));
//routes
const Emails = lazy(() => import("./routes/AdvisorPortal/emails"));
const SingleThread = lazy(() => import("./routes/AdvisorPortal/emails/SingleThread"));
const PricingPage = lazy(() => import("./routes/publicPages/Pricing-Page"))
// const TopicSelection = lazy(() => import("./routes/publicPages/onboarding/topic-selection"))
const onboarding = lazy(() => import("./routes/publicPages/onboarding/onboarding-hub"))
// const IntakeForm = lazy(() => import("./routes/publicPages/onboarding/intake-form"))
// const OnboardingCalendly = lazy(() => import("./routes/publicPages/onboarding/Calendly"))
const LearningLabDashboard = lazy(() =>
  import("./routes/learning-lab/learn-lab-dashboard")
);

// const OnboardingPayment = lazy(() => import("./routes/publicPages/onboarding/Payment/Payment"))
const PrivacyPolicy = lazy(() => import("./routes/disclosures/privacy-policy"));
const NoticeAndDisclamer = lazy(() =>
  import("./routes/disclosures/notice-and-disclaimer")
);
const AdditionalTerms = lazy(() =>
  import("./routes/disclosures/additional-terms")
);
const TermsOfUse = lazy(() => import("./routes/disclosures/terms-of-use"));

const TransferPartners = lazy(() =>
  import("./routes/TravelLounge/pages/TransferPartners")
);
const HowToUsePoints = lazy(() =>
  import("./routes/TravelLounge/pages/HowToUsePoints")
);
const AbundoLove = lazy(() => import("./routes/Client-Portal/Abundo-Love"));
const OtherWaysToAccumulatePoints = lazy(() =>
  import("./routes/TravelLounge/pages/OtherWaysToAccumulatePoints")
);
const BestCreditCards = lazy(() =>
  import("./routes/TravelLounge/pages/best-credit-cards/best-credit-cards")
);
const AccumulatingPoints = lazy(() =>
  import("./routes/TravelLounge/pages/AccumulatingPoints")
);
const TravelLoungeHome = lazy(() =>
  import("./routes/TravelLounge/travel-lounge-home")
);
const LearningLabVideo = lazy(() =>
  import("./routes/learning-lab/learn-lab-video")
);
const LearningLabTopic = lazy(() =>
  import("./routes/learning-lab/learn-lab-topic")
);




const LookCoolFlyingFirstClass = lazy(() =>
  import("./routes/TravelLounge/pages/LookCoolFlyingFirstClass")
);
const TwentyMoreTravelHacks = lazy(() =>
  import("./routes/TravelLounge/pages/TwentyMoreTravelHacks")
);
const TravelResourcesToLiveBy = lazy(() =>
  import("./routes/TravelLounge/pages/TravelResourcesToLiveBy")
);
const OtherStrategiesForCheapFlights = lazy(() =>
  import("./routes/TravelLounge/pages/OtherStrategiesForCheapFlights")
);
const HowToFindCheapFlights = lazy(() =>
  import("./routes/TravelLounge/pages/HowToFindCheapFlights")
);
const OtherBenefitsOfPoints = lazy(() =>
  import("./routes/TravelLounge/pages/OtherBenefitsOfPoints")
);
const PointsPilot = lazy(() =>
  import("./routes/TravelLounge/pages/pointsPilot/points-pilot")
);

const YouvestHome = lazy(() => import("./routes/Client-Portal/Youvest"));
const YouvestAccount = lazy(() => import("./components/Youvest/components/Account"));
const YouvestStartPage = lazy(() => import("./components/Youvest/components/Trades/StartPage"));
const YouvestTradePage = lazy(() => import("./components/Youvest/components/Trades/TradePage"));
const YouvestPercentageAccountRebalance = lazy(() => import("./components/Youvest/components/Trades/PercentageAccountRebalance.jsx"));
const YouvestTransitionPage = lazy(() => import("./components/Youvest/components/Trades/TransitionPage"));
const AdvisorYouvestHome = lazy(() => import("./routes/AdvisorPortal/household/Youvest"));

const financialStory = lazy(() => import("./routes/Client-Portal/FinancialStory"))

const AdminDashboard = lazy(() =>
  import("./routes/admin-portal/admin-dashboard")
);
const LoginActivity = lazy(() =>
  import("./routes/AdvisorPortal/login-activity")
);
const IndividualAccount = lazy(() =>
  import("./routes/AdvisorPortal/client/individual-account")
);
const CustomFormsSmartTable = lazy(() => import("./routes/AdvisorPortal/CustomForms/SmartTable"));
const ViewFormAnswers = lazy(() => import("./routes/AdvisorPortal/CustomForms/ViewFormAnswers"));
const ApprovedUsers = lazy(() =>
  import("./routes/AdvisorPortal/approved-users")
);
const AdvisorDashboard = lazy(() =>
  import("./routes/AdvisorPortal/Dashboard")
);
const RemindersDashboard = lazy(() => import("./routes/AdvisorPortal/reminders/RemindersDashboard"));
const NotificationsSmartTable = lazy(() => import("./routes/AdvisorPortal/Notifications/Notifications-Smart-Table"))
const OnboardingDashboard = lazy(() => import("./routes/AdvisorPortal/onboarding/OnboardingDashboard"));
const ProspectDashboard = lazy(() => import("./routes/AdvisorPortal/prospects/ProspectBoard"));
const MeetingDashboard = lazy(() => import("./routes/AdvisorPortal/meetings/MeetingDashboard"))


const EditAllocation = lazy(() =>
  import("./routes/admin-portal/investment-controls/edit-allocation")
);

const InvestmentControlsDashboard = lazy(() =>
  import("./routes/admin-portal/investment-controls/dashboard")
);
const EditContent = lazy(() =>
  import("./routes/Content/learningLab/edit-content")
);
const EditLearningLabDashboard = lazy(() =>
  import("./routes/Content/learningLab/edit-learning-lab-dashboard")
);
const EditBlog = lazy(() => import("./routes/Content/blog/edit-blog"));
const BlogDashboard = lazy(() =>
  import("./routes/Content/blog/blog-dashboard")
);

const CalendlyManager = lazy(() =>
  import("./routes/admin-portal/calendly-manager")
);
const EditPortfolio = lazy(() =>
  import("./routes/admin-portal/investment-controls/edit-portfolio")
);




const AdminClientList = lazy(() =>
  import("./routes/admin-portal/clients/client-list")
);
const ClientDashboard = lazy(() => import("./routes/Client-Dashboard"));
const Login = lazy(() => import("./routes/Login/Login"));
const PubHomePage = lazy(() =>
  import("./routes/publicPages/home-page")
);
const whatToExpect = lazy(() => import("./routes/publicPages/what-to-expect"));
const Signup = lazy(() => import("./routes/Login/Signup"));
const ForgotPassword = lazy(() => import("./routes/Login/forgot-password"));
const Careers = lazy(() => import("./routes/publicPages/careers"));
const JustGettingStarted = lazy(() => import("./routes/publicPages/who-we-help/just-getting-started"));
const ConsideringRetirement = lazy(() => import("./routes/publicPages/who-we-help/considering-retirement"));
const MidCareerProfessionals = lazy(() => import("./routes/publicPages/who-we-help/mid-career-professionals"));
const DIYer = lazy(() => import("./routes/publicPages/who-we-help/diyer"));
const NewAdvisor = lazy(() => import("./routes/publicPages/who-we-help/new-advisor"));
const FeeSavingsCalculator = lazy(() => import("./routes/publicPages/fee-savings-calculator"));
const OurStory = lazy(() => import("./routes/publicPages/OurStory"));
const Faq = lazy(() => import("./routes/publicPages/Faq"));
const Media = lazy(() => import("./routes/publicPages/media"));
const BlogHome = lazy(() => import("./routes/publicPages/Blog/blog-home"));
const BlogPost = lazy(() => import("./routes/publicPages/Blog/blog-post"));
const BlogCateogry = lazy(() => import("./routes/publicPages/Blog/Blog-Category"));
const UnclaimedMeetings = lazy(() => import('./routes/AdvisorPortal/meetings/components/unclaimedMeetings/UnclaimedTable'))
const RiskToleranceForm = lazy(() => import('./routes/Client-Portal/Risk-Tolerance/Form'))
const EndPageRiskTolerance = lazy(() => import('./routes/Client-Portal/Risk-Tolerance/EndPage'))

// Meeting Mode ------------------------------------------------------------------------------ Meeting Mode
const MeetingModeFinancialStory = lazy(() => import("./routes/AdvisorPortal/meeting-mode/financial-story/financial-story"));
const MeetingModeValues = lazy(() => import("./routes/AdvisorPortal/meeting-mode/Values"));
const MeetingModeCashflow = lazy(() => import("./routes/AdvisorPortal/meeting-mode/Cashflow"));
const MeetingModeRiskTolerance = lazy(() => import("./routes/AdvisorPortal/meeting-mode/RiskTolerance"));
const MeetingModeViewFormAnswers = lazy(() => import("./routes/AdvisorPortal/meeting-mode/RiskTolerance/ViewFormAnswers"));
const AdvisorResources = lazy(() =>
  import("./routes/AdvisorPortal/Advisor-Resources")
)
const AdvisorResourcePage = lazy(() =>
  import("./routes/AdvisorPortal/Advisor-Resources/components/AdvisorResourcePage")
)

const AnalyticsModule = lazy(() => import('./routes/AdvisorPortal/Analytics'));

const AdvisorSettings = lazy(() => import('./routes/AdvisorPortal/Settings'));
const ManagerDashboard = lazy(() => import('./routes/manager-portal/manager-dashboard'));

// End Meeting Mode ------------------------------------------------------------------------------ End Meeting Mode

//--------------------------

//mixpanel

const App = () => {


  if (process.env.NODE_ENV === "production") {

    // const TRACKING_ID = "UA-146501500-3";
    const TRACKING_ID = "G-SGWZ3638X5";
    ReactGA.initialize(TRACKING_ID);
    // ReactGA.pageview(window.location.pathname + window.location.search);

  }

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      const pathName = window.location.pathname + window.location.search;
      ReactGA.send({ hitType: "pageview", page: pathName });
    }
  }, []);

  //react query
  const queryClient = new QueryClient()
  return (
    <div className="App">
      <Helmet>
        <title>Abundo Wealth | Advice-Only Financial Planning</title>
        <meta
          name="description"
          content="Abundo provides ongoing, Advice-Only comprehensive financial planning at a low cost and without conflicts of interest."
        />
        <meta
          name="google-site-verification"
          content="0OLDiDXXqGQ_wBXoQUjSezaQseCmWhSIZEs9oYlO_f4"
        />

        <meta
          name="keywords"
          content="Financial advisor, Financial planning, Financial advisor,Advice-Only financial advisor, Advice-Only, Advice-Only financial advice, Flat fee financial advisor,Flat fee financial advice,Best financial advisor,Advice-only financial advisor,Best financial advice,Low cost financial advice,Comprehensive, Transparent, Travel hacks, Credit card points, Student loans, buying a home, buy a home, home buying, FI-RE, Financial independence, retire early, cost of financial advice, virtual financial advice, virtual financial planner, virtual financial planning, financial literacy, financial freedom, investing, digital nomads, health care workers, teachers, travel lovers"
        />
      </Helmet>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <AppProvider>
              <div className="bg-gray-100 lg:bg-gray-100 h-0">
                <Router>
                  <Switch>
                    <Suspense fallback={<LoadingPage />}>
                      {" "}
                      <CustomDragLayer />
                      <Route exact path="/" component={PubHomePage} />
                      <Route exact path="/what-to-expect" component={whatToExpect} />

                      <Route exact path="/login" component={Login} />
                      <Route exact path="/signup" component={Signup} />
                      <Route
                        exact
                        path="/forgotpassword"
                        component={ForgotPassword}
                      />
                      <Route exact path="/careers" component={Careers} />
                      <Route exact path="/media" component={Media} />
                      <Route exact path="/who-we-help/just-getting-started" component={JustGettingStarted} />
                      <Route exact path="/who-we-help/thinking-about-retirement" component={ConsideringRetirement} />
                      <Route exact path="/who-we-help/mid-career-professionals" component={MidCareerProfessionals} />
                      <Route exact path="/who-we-help/diyer-seeking-guidance" component={DIYer} />
                      <Route exact path="/who-we-help/looking-for-advisor" component={NewAdvisor} />
                      <Route exact path="/our-story" component={OurStory} />
                      <Route exact path='/about' component={OurStory} />
                      <Route exact path="/faq" component={Faq} />
                      <Route exact path='/fee-savings-calculator' component={FeeSavingsCalculator} />
                      <Route exact path="/blog" component={BlogHome} />{" "}
                      <Route exact path='/blog/categories/:category_href' component={BlogCateogry} />
                      <Route exact path="/blog/:blog_href" component={BlogPost} />
                      {/* Onboarding */}
                      <Route exact path="/get-started" component={PricingPage} />
                      <Route
                        exact
                        path="/onboarding/:id"
                        component={onboarding}
                      />
                      <PrivateRoute
                        exact
                        path="/stripe-transition/:id"
                        component={StripeTransition}
                      />

                      <PrivateRoute
                        exact
                        path="/dashboard"
                        component={ClientDashboard}
                      />
                      {/* Legal ------------------------------------------------------------------- Legal */}
                      <Route
                        exact
                        path="/legal/terms_of_use"
                        component={TermsOfUse}
                      />
                      <Route
                        exact
                        path="/legal/additional_terms"
                        component={AdditionalTerms}
                      />
                      <Route
                        exact
                        path="/legal/notice_and_disclamer"
                        component={NoticeAndDisclamer}
                      />
                      <Route
                        exact
                        path="/legal/privacy_policy"
                        component={PrivacyPolicy}
                      />
                      <PrivateRoute
                        exact
                        path='/risk-tolerance/form'
                        component={RiskToleranceForm}
                      />
                      <PrivateRoute
                        exact
                        path='/risk-tolerance/end-page'
                        component={EndPageRiskTolerance}
                      />
                      {/*Learning Lab ------------------------------------------------------- Learning Lab */}
                      <PrivateRoute
                        exact
                        path="/learning_lab"
                        component={LearningLabDashboard}
                      />
                      <PrivateRoute
                        exact
                        path="/learning_lab/:section"
                        component={LearningLabTopic}
                      />
                      <PrivateRoute
                        exact
                        path="/learning_lab/:section/:id"
                        component={LearningLabVideo}
                      />{" "}
                      {/* ----------------------------------------- Travel Lounge */}
                      <PrivateRoute
                        exact
                        path="/travel_lounge"
                        component={TravelLoungeHome}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/accumulating_points"
                        component={AccumulatingPoints}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/best_credit_cards"
                        component={BestCreditCards}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/other_ways_to_accumulate_points"
                        component={OtherWaysToAccumulatePoints}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/how_to_use_points_properly"
                        component={HowToUsePoints}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/transfer_partners"
                        component={TransferPartners}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/points_pilot"
                        component={PointsPilot}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/other_benefits_of_points"
                        component={OtherBenefitsOfPoints}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/how_to_find_cheap_flights"
                        component={HowToFindCheapFlights}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/other_strategies_for_cheap_flights"
                        component={OtherStrategiesForCheapFlights}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/travel_resources_to_live_by"
                        component={TravelResourcesToLiveBy}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/20_more_travel_hacks"
                        component={TwentyMoreTravelHacks}
                      />{" "}
                      <PrivateRoute
                        exact
                        path="/travel_lounge/look_cool_flying_first_class"
                        component={LookCoolFlyingFirstClass}
                      />{" "}
                      {/* ---------------------------------------------- Values Exercise */}
                      <PrivateRoute
                        exact
                        path="/values/exercise"
                        component={Values}
                      />
                      {/* ------------------------------------------ End Values Exercise */}
                      {/* ------------------------------------------ Priorities */}
                      <PrivateRoute
                        exact
                        path="/financial-story"
                        component={financialStory}
                      />
                      {/* ------------------------------------------ End Priorities */}
                      {/* ------------------------------------------  Investing Hub*/}
                      <PrivateRoute
                        exact
                        path="/youvest/:section"
                        component={YouvestHome}
                      />
                      <PrivateRoute
                        exact
                        path="/youvest/account/:id"
                        component={YouvestAccount}
                      />
                      <PrivateRoute
                        exact
                        path="/youvest/trade-recommendations/transition/:action_item_id/:account_id"
                        component={YouvestTransitionPage}
                      />
                      <PrivateRoute
                        exact
                        path="/youvest/trade-recommendations/start/:action_item_id/:account_id"
                        component={YouvestStartPage}
                      />

                      <PrivateRoute
                        exact
                        path="/youvest/trade-recommendations/:action_item_id/:account_id/:trade_run_id/:ticker"
                        component={YouvestTradePage}
                      />
                      <PrivateRoute
                        exact
                        path="/youvest/rebalance-percentage/:action_item_id/:account_id/:trade_run_id"
                        component={YouvestPercentageAccountRebalance}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/youvest/:household_id/:section'
                        component={AdvisorYouvestHome}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/youvest/:household_id/account/:id'
                        component={YouvestAccount}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/youvest/:household_id/trade-recommendations/start/:action_item_id/:account_id'
                        component={YouvestStartPage}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/youvest/:household_id/trade-recommendations/transition/:action_item_id/:account_id'
                        component={YouvestTransitionPage}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/youvest/:household_id/rebalance-percentage/:action_item_id/:account_id/:trade_run_id"
                        component={YouvestPercentageAccountRebalance}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/youvest/:household_id/trade-recommendations/:action_item_id/:account_id/:trade_run_id/:ticker'
                        component={YouvestTradePage}
                      />

                      <AdvisorRoute
                        exact
                        path='/crm/emails/:email_label/:page_number'
                        component={Emails}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/emails/:email_label/thread/:thread_id"
                        component={SingleThread}
                      />




                      <PrivateRoute
                        exact
                        path="/abundo-love-corner"
                        component={AbundoLove}
                      />


                      {/* Manager ------------------------------------------------------------------- Manager */}
                      <ManagerRoute
                        exact
                        path="/crm/manager/dashboard"
                        component={ManagerDashboard}
                      />
                      {/* Advisor ------------------------------------------------------------------- Advisor */}{" "}
                      <AdvisorRoute
                        exact
                        path="/crm/households/smart-table/:view_id"
                        component={HouseholdsSmartTable}
                      />

                      <AdvisorRoute
                        exact
                        path="/crm/advisor/settings/:user_id"
                        component={AdvisorSettings}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/users/smart-table/:view_id"
                        component={UsersSmartTable}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/forms/smart-table/:view_id"
                        component={CustomFormsSmartTable}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/forms/:submission_id"
                        component={ViewFormAnswers}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/households/:household_id"
                        component={HouseholdHome}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/resources"
                        component={AdvisorResources}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/resources/:resource_id"
                        component={AdvisorResourcePage}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/dashboard"
                        component={AdvisorDashboard}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/onboarding/smart-table/:view_id"
                        component={OnboardingDashboard}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/prospects/smart-table/:view_id"
                        component={ProspectDashboard}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/meetings/smart-table/:view_id"
                        component={MeetingDashboard}
                      />

                      <AdvisorRoute
                        exact
                        path="/crm/reminders"
                        component={RemindersDashboard}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/notifications/smart-table/:view_id"
                        component={NotificationsSmartTable}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/pre_approved_users"
                        component={ApprovedUsers}
                      />
                      <AdminRoute
                        exact
                        path="/crm/analytics"
                        component={AnalyticsModule}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/investment_accounts/:user_id/:account_id"
                        component={IndividualAccount}
                      />
                      <AdvisorRoute
                        exact
                        path="/advisor/login_activity"
                        component={LoginActivity}
                      />
                      {/* End Advisor ------------------------------------------------------------------- End Advisor */}{" "}
                      {/* Meeting Mode */}
                      <AdvisorRoute
                        exact
                        path="/crm/meeting-mode/:household_id/financial-story"
                        component={MeetingModeFinancialStory}
                      />
                      <AdvisorRoute
                        exact
                        path="/crm/meeting-mode/:household_id/values"
                        component={MeetingModeValues}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/meeting-mode/:household_id/cashflow'
                        component={MeetingModeCashflow}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/meeting-mode/:household_id/risk-tolerance'
                        component={MeetingModeRiskTolerance}
                      />
                      <AdvisorRoute
                        exact
                        path='/crm/meeting-mode/:household_id/risk-tolerance/:submission_id'
                        component={MeetingModeViewFormAnswers}
                      />
                      {/* End Meeting Mode */}
                      {/* Admin ------------------------------------------------------------------- Admin */}{" "}
                      <AdminRoute
                        exact
                        path="/admin/dashboard"
                        component={AdminDashboard}
                      />{" "}
                      <AdminRoute
                        exact
                        path="/admin/investment_controls"
                        component={InvestmentControlsDashboard}
                      />{" "}
                      <AdminRoute
                        exact
                        path="/admin/investment_controls/edit_allocation/:allocation_name"
                        component={EditAllocation}
                      />
                      <AdminRoute
                        exact
                        path="/admin/investment_controls/edit_portfolio/:portfolio_name"
                        component={EditPortfolio}
                      />{" "}
                      <AdminRoute
                        exact
                        path="/admin/calendly_manager"
                        component={CalendlyManager}
                      />
                      <AdminRoute
                        exact
                        path="/admin/clients"
                        component={AdminClientList}
                      />
                      {/* End Admin ------------------------------------------------------------------- End Admin */}
                      {/* Content ------------------------------------------------------------------- End Content */}
                      {/* Blog Content Manager ---------------------------------------------------------------------- Blog Content Manager */}
                      <AuthorizedRoute
                        exact
                        path="/content/blog/dashboard"
                        component={BlogDashboard}
                        authorizationType={AUTHORIZATION.BLOG_EDITOR}
                      />
                      <AuthorizedRoute
                        exact
                        path="/content/blog/edit/:blog_post_id"
                        component={EditBlog}
                        authorizationType={AUTHORIZATION.BLOG_EDITOR}
                      />
                      {/* End Blog Content Manager ---------------------------------------------------------End Blog Content Manager */}
                      {/* Learning Lab Content Manager ---------------------------------------------------------Learning Lab Content Manager */}{" "}
                      <AuthorizedRoute
                        exact
                        path="/content/learning_lab/dashboard"
                        component={EditLearningLabDashboard}
                        authorizationType={AUTHORIZATION.LEARNING_LAB_EDITOR}
                      />
                      <AuthorizedRoute
                        exact
                        path="/content/learning_lab/edit/:content_id"
                        component={EditContent}
                        authorizationType={AUTHORIZATION.LEARNING_LAB_EDITOR}
                      />
                      {/* End Learning Lab Content Manager ---------------------------------------------------------End Learning Lab Content Manager */}
                      {/* End Content -------------------------------------------------------------------- End Content */}
                      {/* Old Dead Links */}
                      <Route
                        exact
                        path="/post/what-is-esg-investing-any-why-is-it-becoming-such-a-huge-trend"
                        component={BlogDashboard}
                      />{" "}
                      <Route
                        exact
                        path="/post/activeversuspassive"
                        component={BlogDashboard}
                      />
                      {/* End Old dead Links */}
                    </Suspense>{" "}
                    {/* <Route component={NotFound} /> */}
                  </Switch>
                </Router>
              </div>
            </AppProvider>
          </AuthProvider>{" "}
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ErrorBoundary>
    </div>
  );
};

export default App;
